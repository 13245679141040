$package_title_line_height: 21px;
$package_description_line_height: 24px;

.gig-page .package-content > header {
    padding-bottom: 10px;
    line-height: $package_title_line_height;

    h3 {
        display: flex;
        justify-content: space-between;

        .price {
            white-space: nowrap;
        }

        span {
            font-size: 20px;
            line-height: $package_description_line_height;
            font-weight: 400;
        }
    }
}

.gig-page .triple .package-content header {
    h3 {
        .price-wrapper {
            display: flex;
            flex-direction: column;
        }
    }
}

.gig-page .single .package-content header h3 {
    .price-wrapper {
        display: flex;
        flex-direction: column;

        .price {
            padding-right: 0;
        }
    }
}
