@use '../GigPage/base' as base;

@mixin reveal-vertical($duration: 1s, $delay: 0s) {
    background-color: $black;
    animation: reveal-vertical-rect $duration linear $delay forwards;
}

.gig-page .packages-tabs {
    margin: 0 -#{base.$page_side_padding_sml};

    &.with-ai-package {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    @include media-query-grid(sm) {
        margin: 0 -#{base.$page_side_padding};
    }

    @include media-query-grid(md) {
        border: 1px solid color($grey, 500);
        margin: 0;
    }
}

.reveal-vertical-content {
    visibility: hidden;
    background-color: $black;
    border-style: none;
    transform: visibility 2s ease;
    animation: reveal-vertical-circle 1s linear 0.2s forwards;

    // Package content has a delay when selected, so the children has a fade in animation
    > * {
        opacity: 0;
        animation: fade-in 0.3s ease forwards;
        animation-delay: 0.85s;
    }
}

@keyframes reveal-vertical-circle {
    0% {
        clip-path: ellipse(100% 0% at 50% 0%);
    }

    100% {
        clip-path: ellipse(150% 150% at 50% 50%);
        visibility: visible;
    }
}

@keyframes reveal-vertical-rect {
    0% {
        clip-path: inset(0 0 100% 0);
    }

    100% {
        clip-path: inset(0 0 0 0);
        visibility: visible;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
