/* sass-lint:disable class-name-format */
/* stylelint-disable selector-class-pattern */

/*
    This sets the order for all components on the page when the viewport
    is smaller than 900px. This does NOT apply on views larger than 900px.

    This also sets the order for the links in the TopNav component.
*/

.gig-page-wrapper {
    // GigOutOfOffice
    // Topnav link: Overview
    .out-of-office,
    .nav-overview {
        order: 10;
    }

    // Overview
    .gig-overview {
        order: 30;
    }

    // Coupon Banner
    .banner-message {
        order: 31;
    }

    // UGC Translation Button
    .ugc-translation-button-wrapper {
        order: 32;
    }

    // Gallery
    .gig-gallery-component {
        order: 40;
    }

    // PackagesTabs
    .packages-tabs {
        order: 50;
    }

    .contact-seller {
        order: 55;
    }

    .sidebar-highlights {
        order: 55;
    }

    .highly-responsive {
        order: 58;
    }

    // Reviews Snippet
    .gig-reviews-snippet {
        order: 60;
    }

    .carousels-barrier {
        order: 65;
    }

    // Description
    // Topnav link: Description
    .gig-description,
    .nav-description,
    .nav-about-this-gig,
    .nav-theService {
        order: 70;
    }

    .gig-workflow,
    .gig-work-process {
        order: 75;
    }

    // ProCertificateBanner
    .pro-certificate-banner {
        order: 80;
    }

    // Header: About the Seller / Studio
    // Topnav link: About the Seller / Studio
    .about-the-seller,
    .nav-aboutSeller,
    .nav-aboutMe {
        order: 90;
    }

    // SellerCard / StudioCard
    .below-the-fold-experiential-seller-card-order {
        order: 100;
    }

    // Portfolio
    .portfolio-external-wrapper {
        order: 110;
    }

    // MoreServices
    .more-services {
        order: 120;
    }

    // ProVideo
    .pro-video {
        order: 130;
    }

    // GigDisclaimer
    .disclaimer {
        order: 140;
    }

    // Topnav link: Compare Packages
    .nav-packagesTable {
        order: 150;
    }

    // Topnav link: Order Details
    .nav-orderDetails {
        order: 150;
    }

    // Topnav link: Recommendations
    .nav-recommendations {
        order: 160;
    }

    // PersonalizedSection: COMPARE
    .personalized-content-compare {
        order: 160;
    }

    // Faq
    // Topnav link: FAQ
    .faq-collapsable,
    .nav-faq {
        order: 170;
    }

    // Reviews
    // Topnav link: Reviews
    .gig-page-reviews,
    .reviews-package,
    .nav-reviews {
        order: 180;
    }

    // Tags
    .gig-tags-container {
        order: 190;
    }

    // GigCardCarouselWrapper: otherGigsBy
    .gigs-other-gigs-by {
        order: 200;
    }

    // PersonalizedSection: explore
    .personalized-content-explore {
        order: 210;
    }
}

.gig-page-wrapper.non-experiential {
    // GigOutOfOffice
    // Topnav link: Overview
    .out-of-office,
    .nav-overview {
        order: 10;
    }

    // Overview
    .gig-overview {
        order: 20;
    }

    // Coupon Banner
    .banner-message {
        order: 21;
    }

    // UGC Translation Button
    .ugc-translation-button-wrapper {
        order: 22;
    }

    // SellerCard
    .seller-card {
        order: 30;
    }

    .pro-freelancer-banner {
        order: 38;
    }

    .expert-list {
        order: 39;
    }

    .highlights {
        order: 40;
    }

    // Reviews Snippet
    .gig-reviews-snippet {
        order: 50;
    }

    .carousels-barrier {
        order: 55;
    }

    // Description
    // Topnav link: Description
    .about-gig,
    .nav-aboutThisGig {
        order: 60;
    }

    // Gallery
    .gig-gallery-component {
        order: unset;
    }

    // GigDisclaimer
    .disclaimer {
        order: 80;
    }

    .notable-clients {
        order: 90;
    }

    // Portfolio
    .portfolio-external-wrapper {
        order: 100;
    }

    .gig-workflow,
    .gig-work-process {
        order: 110;
    }

    // PackagesTabs
    .packages-tabs {
        order: 120;
    }

    .contact-seller {
        order: 130;
    }

    .sidebar-highlights {
        order: 130;
    }

    .highly-responsive {
        order: 140;
    }

    // ProCertificateBanner
    .pro-certificate-banner {
        order: 150;
    }

    // MoreServices
    .more-services {
        order: 160;
    }

    // ProVideo
    .pro-video {
        order: 170;
    }

    // Topnav link: Compare Packages
    .nav-packages-table {
        order: 180;
    }

    // Topnav link: Order Details
    .nav-order-details {
        order: 190;
    }

    // Topnav link: Recommendations
    .nav-recommendations {
        order: 200;
    }

    // PersonalizedSection: COMPARE
    .personalized-content-compare {
        order: 210;
    }

    // Faq
    // Topnav link: FAQ
    .faq-collapsable,
    .nav-faq {
        order: 220;
    }

    // Reviews
    // Topnav link: Reviews
    .gig-page-reviews,
    .reviews-package,
    .nav-reviews {
        order: 230;
    }

    // Tags
    .gig-tags-container {
        order: 240;
    }

    // GigCardCarouselWrapper: otherGigsBy
    .gigs-other-gigs-by {
        order: 250;
    }

    // PersonalizedSection: explore
    .personalized-content-explore {
        order: 260;
    }
}
