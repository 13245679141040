// sass-lint:disable variable-name-format

$base_multiplier: 32px;
$page_side_padding_sml: 24px;
$page_side_padding: $base_multiplier;
$page_side_padding_double: calc($base_multiplier * 2);
$box_bottom_margin: $base_multiplier;
$box_bottom_margin_half: calc($base_multiplier / 2);
$box_bottom_margin_double: calc($base_multiplier * 2);
$black_10: rgba(0, 0, 0, 0.1);
$black_15: rgba(0, 0, 0, 0.15);
$top_nav_height: 54px;

// mobile slider breakpoints to match the ones in the JS desktop slider
// please make sure to update these if the JS ones change

$GIG_GRID_XXLRG: 1360px;
$GIG_GRID_XLRG: 1240px;
$GIG_GRID_LRG: 900px;
$GIG_GRID_MED: 750px;
$GIG_GRID_SML: 530px;
$packages_tab_spacing: 20px;
$page_max_width_xl: 1240px;
